@import '../../scss/variables';
@import '../../scss/functions';
@import '../../scss/mixins/direction';

.card-wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  padding: 50px 0;

  .card {
    max-width: 540px;
    width: 100%;
  }
}

.card {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  margin-bottom: 0;
}

.card-body {
  padding: 3rem;

  .card-title:last-child {
    margin-bottom: 0;
  }
}

.card-title {
  text-align: center;
  margin-bottom: 1.75rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.signUp-position-relative {
  > div {
    width: 100%;
    margin-top: 15px;
  }
;
}

.login-button {
  max-width: 100%;
  width: 100%;
  height: 40px;
  font-size: 20px;
  padding: 0;
}

.rounded-pills {
  color: white;
  width: 100%;
  border-radius: 5px !important;
  text-align: center;
}

.sbm_button {
  margin-top: 15px;
}

.account-nav__item {
  .historyIcon-svg {
    stroke: none;
  }
}

.account-nav__item a {
  display: block;
  color: $body-font-alt-color;
  font-size: 16px;
  padding: 5px 26px;
}

.account-nav__item {
  fill: transparent;
  stroke: #000000;
}

.account-nav__item--active {
  background-color: #f7f7f7
}

.account-nav__item--active > * {
  stroke: $main-theme-color-light;
}

.account-nav ul .account-nav__item {
  cursor: pointer;
  padding: 11px 0;

  &:hover {
    background-color: #f7f7f7;
  }
}

.account-nav__title {
  font-size: 32px;
  color: #000000;
  margin-bottom: 4rem;
  text-align: center;
}

.account-layout-wrapper {
  display: flex;
  justify-content: center;

  .account-layout {
    width: 25%;
  }

  .account-layout-desktop {
    width: 75%;
  }
}

.account-nav {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.account-links-body {
  width: max-content;
}

.login-ss {
  .account-menu__form-forgot-link {
    a {
      margin-left: 0 !important;
    }
  }
}

.account-menu__form-forgot-link {
  $local-offset: 5px;
  margin-top: 15px;
  border-radius: 2px;
  font-size: 15px;
  font-weight: $font-weight-medium;
  background: $btn-light-bg-color;
  color: $btn-light-font-color;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;
  text-decoration: underline;
  display: block;
  max-width: max-content;
  width: 100%;

  @include direction {
    #{$inset-inline-end}: $local-offset;
  }

  &:hover {
    background: none;
    color: #000C66;
    text-decoration: underline;
  }

  &:active {
    background: none;
    color: #3d464d;
  }
}

@mixin local-layout($columns, $gutter) {
  .addresses-list a:not(.address-card__footer a) {
    width: calc((100% - #{($columns - 1) * $gutter}) / #{$columns});
    padding: 2rem;
  }
  .addresses-list__item {
    max-width: calc((100% - #{($columns - 1) * $gutter}) / #{$columns});
  }
  .addresses-list__divider:nth-child(2n) {
    width: $gutter;
  }
  .addresses-list__divider:nth-child(#{$columns * 2}n) {
    width: 100%;
    height: $gutter;
  }
}

.addresses-list__item {
  flex-basis: 0;
  flex-grow: 1;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
}

.addresses-list__item--new {
  border-radius: $card-border-radius;
  border: $card-border-width dashed #ebebeb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  height: 124px;

  &:hover {
    color: #808080;
  }
}

.addresses-list__item--new:hover .addresses-list__plus {
  border-color: #e0e0e0;

  &::before,
  &::after {
    background-color: #dbdbdb;
  }
}

.address-card {
  position: relative;
  max-width: calc((100% - 32px) / 3);
}

.address-card__body {
  padding: 1rem;
  font-size: 15px;
  line-height: 18px;
}

.address-card--featured {
  .address-card__body {
    padding: 2rem;
  }

  .address-card__name {
    font-weight: $font-weight-bold;
  }


  @media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .address-card__body {
      padding: 1.5rem;
    }
  }
  @media (max-width: breakpoint(sm-end)) {
    .address-card__body {
      padding: 1.375rem;
    }
  }
}

.address-card__name {
  font-size: 16px;
  font-weight: $font-weight-bold;
}

.address-card__row + .address-card__row {
  margin-top: .75rem;
}

.address-card__row-title {
  font-size: 12px;
  color: $card-font-muted-color;
}

.addresses-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  a:not(.address-card__footer a) {
    border-radius: 2px;
    border: 2px dashed #ebebeb;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 32px) / 3);
    padding: 2rem;
  }
}

.address-card__footer {
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
}

.address-remove-btn {
  cursor: pointer;
  margin-left: 13px;

  &:hover {
    color: $main-theme-color-light;

    .mobile-footer-dropdowns-list {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.addresses-list__divider:last-child {
  display: none;
}

.address-page-body {
  border: 2px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
}

.address-page-title {
  padding-bottom: 33px;
  border-bottom: 1px solid $card-border-color;
  margin-bottom: 33px;
}

.select-container {
  position: relative;
}

.form-group {
  margin-bottom: 1.5rem;
  min-height: 42px;
  padding-left: unset;

  &.checkout-style {
    margin-bottom: 0 !important;
  }

  select,
  input {
    border: 1px solid #aaa;
    border-radius: 5px;
    height: 42px;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #3d464d;
    padding: 10px;

    &[type="date"]:hover {
      cursor: pointer;
    }
  }

  select {
    margin-bottom: 3px;
  }
}

.account-menu__form {
  padding: 0 30px 32px;

  .form-group {
    margin-bottom: 1rem;
  }
}

.new-adresses {

  div {
    width: 100%;
    display: flex;
    gap: 0 15px;
  }

  .form-row {
    div {
      input {
        width: 100%;
      }

    }
  }

  .form-group {
    flex-wrap: wrap;
  }

}

.edit-adresses-wrapper {
  div {
    width: 100%;
    display: flex;
    gap: 0 15px;

  }

  .form-row {
    div {
      input {
        width: 100%;
      }

    }
  }

  .form-group {
    flex-wrap: wrap;

    input {
      width: 100%;
    }

  }
}

.dark-opacity {
  opacity: 1;
}

.checkout-select.checkout-input:not(select) {
  height: 45px;
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: white;
}

.checkout-select {
  width: 100%;
  max-width: 100%;
}

.checkout-input {
  padding: 0 15px;
  font-size: 14px;

  &:focus {
    opacity: 1;
  }
}

.card-header,
.card-footer {
  border-top: 1px solid $card-border-color;
  padding: 1.5rem 2rem;
  border-bottom: none;
  background-color: transparent;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  &:last-child {
    text-align: center;
  }
}

.add-new-address-body {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 30px;
}

.form-control {
  border-radius: 2px;
  line-height: 1.25;
  font-size: 15px;
}

.form-control:focus {
  border-color: black !important;
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
}

.new-adresses-select {
  width: 100%;

  select {
    width: 100%
  }
}

.new-adresses_address {
  width: 100%;

  input {
    width: 100%
  }
}

.order-details-info {
  font-size: 14px;
  color: #707070;
  font-weight: 400;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.625rem 2.5rem 1.375rem;
  border-bottom: 1px solid $card-border-color;

  .order-info-1 {
    width: 180px;
  }

  .order-info-2 {
    width: 150px;
  }
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.625rem 2rem 1.375rem;
}

.order-header__title {
  padding-bottom: 4px;
  margin-bottom: 0;
  color: #121212;
  font-size: 20px;

  @include direction {
    #{$padding-inline-end}: 3.5rem;
    float: $inline-start;
  }
}

.order-header__actions {
  margin-top: -1px;

  @include direction {
    float: $inline-end;
  }
}

.back_list {
  display: flex;
  align-items: center;
  color: #0D47A1;

  &:hover {
    color: #001973;

    svg {
      path {
        fill: #001973;
      }
    }
  }

  svg {
    margin-right: 20px;

    path {
      fill: #0D47A1;
    }
  }
}

.order-wrap-layer-title {
  border-bottom: 1px solid $card-border-color;
  border-top: 1px solid $card-border-color;
  background: #F6F6F6;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.625rem 2.5rem 1.375rem;
  color: #2B2B2B;
  font-weight: 600;
  font-size: 18px;

  .order-title-line-1 {
    width: 180px;
  }

  .order-title-line-2 {
    width: 150px;
  }
}

.order-details-bottom-info {
  width: 300px;
  margin: 2rem 0 0 auto;
  background: #F6F6F6;
  padding: 1.625rem 2rem 1.375rem;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;

  > div {
    width: 100%;
  }
}

.reorder-btn-parent {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.order-details-mobile {
  width: 100%;

  .card-mobile-header {
    .order-details-mobile-header {
      justify-content: space-between;

      .order_title {
        display: flex;
        align-items: center;
        gap: 10px;

        .order_title_text {
          font-weight: 600;
          font-size: 14px;
        }
      }


    }
  }

  .orders-inner-details-mobile {
    .orders-inner-details-header-mobile {
      display: flex;
      flex-direction: column;
      gap: 18px;
      padding: 15px 10px;

      .order-inner-detail-rows {
        display: flex;
        justify-content: space-between;

        .table-heading-descriptions {
          font-size: 14px;
        }
      }
    }
  }

  .orders-products-names-mobile {
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    margin: 0 10px;

    .order-details-product {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 15px 0;

      .order-details-name-width {
        color: #161616;
      }

      .order-details-price {
        color: #626262;
      }
    }
  }

  .order-details-info-mobile {
    .order-details-info-table-mobile {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin: 15px 10px;
      width: auto !important;

      tr {
        th {
          font-size: 14px;
        }
      }
    }

    .reorder-btn-parent {
      margin: 0 10px 15px;
    }
  }
}

.reorder-btn {
  max-width: 300px;
  width: 100%;
  @media screen and (max-width: 391px) {
    max-width: unset;
  }
}

.order-details-mobile-header {
  width: 100%;
  height: 52px;
  background-color: #F7F7F7;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 17px 0 10px;

  a {
    color: #0D47A1;
  }
}

.orders-inner-details-mobile {
  & > div > .table-heading-descriptions {
    margin-right: 15px;
  }
}

.table-heading-descriptions {
  color: #1a2238;
  font-size: 16px;
  font-weight: 600;
}

.mobile-order-info {
  width: 150px;
  text-align: end;
}

.underline {
  border-bottom: 1px solid black;
}

.orders-page-mobile {
  .order-history-card-mobile {
    min-height: auto;

    .order-card-mobile-top-position {
      tr {
        td {
          align-items: center;
          font-size: 16px;

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }

          th {
            font-size: 14px;
          }
        }
      }
    }

    table {
      display: flex;
      justify-content: space-between;

      .table_column {
        display: flex;
        flex-direction: column;
        width: 50%;
      }

      tr {
        width: 100%;

        th {
          font-size: 14px;
        }

        td {
          font-size: 16px;

          @media screen and (max-width: 425px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.order-history-card-mobile {
  margin: 20px 0;

  th {
    font-size: 16px;
    color: #241f23;
  }

  tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 11px 0;
    padding: 0 10px;
  }

  td {
    padding-top: 0;
  }
}

.order-card-mobile-top-position {
  width: 100%;
  background: #F7F7F7;
  display: flex;
  justify-content: space-between;

  & > tr {
    width: 50%;
  }
}

.order-card-mobile-title {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  font-weight: bold;

  a {
    margin-left: 10px;
  }
}

.order-id-mobile-href {
  color: #0D47A1;
  font-weight: 400;
}

.order-card-mobile-options {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.card-table {
  font-size: 15px;

  table {
    width: 100%;
    min-width: 500px;

    @include direction {
      text-align: $inline-start;
    }
  }

  thead th {
    border-bottom: $card-border-width solid $card-border-color;
    text-transform: uppercase;
    color: $body-font-alt-color;
    font-size: 13px;
    font-weight: $font-weight-medium;
    padding: 10px 12px;
  }

  tbody,
  tfoot {
    th,
    td {
      padding: 12px 12px;
      text-align: center;
    }
  }

  tfoot {
    th,
    td {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  tbody + tbody tr,
  tbody + tfoot tr,
  tbody tr + tr {
    th,
    td {
      border-top: 1px solid $card-border-color;
    }
  }

  tbody,
  tfoot {
    tr:hover > * {
      background-color: #f7f7f7;
    }
  }

  th, td {
    @include direction {
      &:first-child {
        #{$padding-inline-start}: 2rem;
      }
      &:last-child {
        #{$padding-inline-end}: 2rem;
      }
    }
  }

  .card-table__body--merge-rows {
    th,
    td {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    tr:first-child {
      th,
      td {
        padding-top: 12px;
      }
    }

    tr:last-child {
      th,
      td {
        padding-bottom: 12px;
      }
    }

    tr + tr {
      th,
      td {
        border-top: none;
      }
    }
  }
}

.input-fields-parent {
  padding: 30px 20px;
}

.personal-info-card {
  width: 100%;
}

.input-paddings {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.account-input-title {
  font-size: 14px;
  font-weight: 100;
}

.input-design {
  height: 35px;

  &.button:hover {
    cursor: pointer;
  }
}

.form-select {
  border: 1px solid #aaa;
  border-radius: 5px;

  &:focus-visible {
    outline: none !important;
  }
}

.button-change-password {
  position: absolute;
  bottom: 14px;
  right: 20px;
  background: white;
  border: none;
  pointer-events: none;
}

.account-save-profile {
  display: flex;
  justify-content: flex-end;
}

.reset-modal {
  flex-direction: column;
  padding: 30px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  align-items: center;
  padding-bottom: 60px;
  border-bottom: 1px solid #e8e8e8;

  svg {
    margin-right: 20px;
  }
}

.modal-href {
  color: #1a66ff !important;
  font-size: 16px;
  text-decoration: revert;
}

.order-details-info-table {
  width: 100% ;
  min-width: 20px ;
  margin-left: auto;

  tr {
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  td {
    text-align: left;
  }
}

.invalid-fields {
  cursor: no-drop;
}

.passwordChange {
  max-width: 600px;
  margin: 0 20px;

  .modal-newpasswords {
    p {
      margin-top: 20px;
    }
  }

  input {
    width: 100%;
  }

  .modalss {
    left: 0;
  }

  .modal-body {
    padding: 0 85px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      margin-bottom: 5px;
      font-size: 14px;
    }

    h1 {
      font-size: 32px;
      text-align: center;
    }
  }

  span {
    p {
      font-size: 12px;
    }
  }

  .modal-body-forms {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding-top: 50px;
  }
}

.modal-confirm-password,
.new-passwords {
  margin: 15px 0 0 0;
}

.error-message-change-password {
  font-size: 12px;
  font-weight: 800;
  color: red;
  text-align: start;
}

.we-recommend

.socials_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  width: 100%;
  margin: 20px 0 20px;
  flex-direction: column;

  .links_icons {
    text-align: center;
    margin: 0 auto;
  }
}

.account-menu-login {
  border-radius: 10px;
  background: #fff;
  color: #3d464d;
  width: 100%;
  max-width: 500px;
  margin: 80px auto;
  border: 1px solid #e6e6e6;

  .socials_content {
    .social-links_line {
      justify-content: space-evenly;
    }

    .links_icons {
      margin: 0 10px;
    }

    .social_line {
      width: 80px;
    }
  }
}

.social-line-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.social_line {
  width: 40px;
  height: 1px;
  background-color: #aaaaaa;
}

.social_line-span {
  margin: 0 10px;
  color: #787878;
  cursor: auto;
}

.social-links_line {
  display: flex;
  width: 100%;
  margin-bottom: 25px;
  justify-content: space-evenly;
}

.modalss {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 202;
  transition: 0.5s;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.modalss.active {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.modal-content {
  background-color: white;
  z-index: 101;
  transform: scale(0.5);
  transition: 0.4s all;
  border: none;
  border-radius: $modal-border-radius;
  box-shadow: $modal-shadow;
  max-width: 1001px;
}

.modal-content.active {
  text-align: center;
  transform: scale(1);
}

@media screen and (min-width: 992px) {
  .account-nav__item--active a {
    color: $main-theme-color-light;
    font-weight: $font-weight-medium;

    path {
      fill: $main-theme-color-light;
      stroke: $main-theme-color-light;
    }
  }

  .account-nav {
    ul {
      padding-bottom: 18px;
    }
  }
}

@media screen and (max-width: 991px) {
  .account-links-body .account-nav__item {
    flex: 50%;
  }

  .account-nav__item {
    width: 100%;
  }

  .account-nav__item a {
    padding: 8px 16px;
  }

  .account-nav__item--active a {
    color: $main-theme-color-light;
    font-weight: $font-weight-medium;
  }

  .account-nav ul .account-nav__item {
    &:last-child {
      border: none !important;
    }
  }

  .account-nav__title {
    text-align: center;
  }

  .account-layout-wrapper {
    flex-wrap: wrap;

    .account-layout {
      width: calc(100% - 90px);
    }
  }

  .account-nav ul {
    justify-content: center;
  }

  .account-nav {
    margin-bottom: 10px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    ul {
      display: flex;
      flex-wrap: nowrap;
      white-space: nowrap;
      width: 100%;
      border-bottom: $card-border-width solid $card-border-color;
    }
  }

  .account-layout-content-margin {
    margin: unset;
  }

  .input-fields-parent {
    padding: 10px 0;
  }

  .modal-title {
    text-align: center;
    padding: 0 20px 40px 20px !important;
    font-size: 20px !important;

    svg {
      width: 22%;
      margin-right: unset !important;
      margin-bottom: auto !important;
    }
  }

  .modal-string {
    font-size: 18px;
    margin: 30px 0;
    padding: 0 20px;
    text-align: center;
  }

  .modal-content-active-register {
    min-height: 0 !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 991px) {
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }

  .card-body {
    padding: 1.5rem;
  }

  @include local-layout(3, 12px);

  .card-header,
  .card-footer {
    padding: 1.25rem 1.5rem;
  }

  .order-header {
    padding: 1.375rem 1.5rem 1.25rem;
  }

  .card-table {
    font-size: 14px;

    th, td {
      @include direction {
        &:first-child {
          #{$padding-inline-start}: 1.5rem;
        }
        &:last-child {
          #{$padding-inline-end}: 1.5rem;
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .card-title {
    font-size: 26px;
    margin-bottom: 1.5rem;
  }

  .card-body {
    padding: 1.375rem;
  }

  .account-menu__form-forgot {
    a {
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      text-transform: capitalize;
      color: #0d47a1;
      margin-top: 8px;
      height: 20px;
      margin-left: auto;
      width: 100%;
    }
  }

  @include local-layout(2, 12px);

  .form-group {
    margin-bottom: 1.125rem;
  }

  .checkout-select,
  .checkout-select-billing {
    max-width: 470px;
    width: 100%;
  }

  .card-header,
  .card-footer {
    padding: 1rem 1.375rem;
  }

  .order-header {
    padding: 1.125rem 1.375rem 1rem;
  }

  .card-table {
    font-size: 14px;

    th, td {
      @include direction {
        &:first-child {
          #{$padding-inline-start}: 1.375rem;
        }
        &:last-child {
          #{$padding-inline-end}: 1.375rem;
        }
      }
    }
  }

  .personal-info-card {
    margin-right: 0;
    margin-left: 0;
  }

  //.modal-content {
  //  width: 95%;
  //}
}

@media screen and (max-width: 600px) {
  .personal-info-card {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .account-save-profile {
    padding-top: 0;
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }

  .order-details-info-table-mobile {
    width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .account-links-body .account-nav__item {
    flex: 0;
  }

  .account-layout-wrapper {
    .account-layout {
      width: 100%;
    }
  }

  .account-nav ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @include local-layout(1, 12px);

  .order-header__actions {
    margin-bottom: .875rem;

    @include direction {
      float: none;
    }
  }

  .input-fields-parent {
    padding: 0;
  }

  .account-menu-login {
    .socials_content {
      .links_icons {
        margin: 0 5px;
      }
    }
  }
}